import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import PageTemplate from "../templates/PageTemplate";
import { useTranslation } from "react-i18next";

export default function Mentions({ navigation }) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getCGV();
  }, []);

  async function getCGV() {
    const docRef = doc(db, i18n?.language === "en" ? "CGV_EN" : "CGV", "CGV");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setData(docSnap.data());
    }
    setIsLoading(false);
  }

  if (isLoading) {
    return null;
  }

  const Card = ({ title, content }) => (
    <div className="mb-10 p-4">
      <h3 className="mb-4 font-semibold text-3xl">{title}</h3>
      <p className="text-base leading-6 text-gray-600 font-regular">
        {content}
      </p>
    </div>
  );
  return (
    <PageTemplate
      content={
        <>
          <Card title={t("footer_legal_mentions")} content={data.cgv} />
          <Card title={""} content={data.mentions} />
        </>
      }
    />
  );
}
