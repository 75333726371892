import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import PageTemplate from "./templates/PageTemplate";
import { onAuthStateChanged } from "firebase/auth";
import { Routes, Route, Navigate } from "react-router-dom";
import Searchs from "./pages/Searchs";
import { useEffect, useState } from "react";
import { auth } from "./config/firebase";
import HowItsWork from "./pages/HowItsWork";
import PublishACar from "./pages/PublishACar";
import { ToastContainer } from "react-toastify";
import Notifications from "./pages/Notifications";
import MySearchs from "./pages/MySearchs";
import MyFinds from "./pages/MyFinds";
import PersonalInformations from "./pages/PersonalInformations";
import WriteComment from "./pages/WriteComment";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Register from "./pages/Auth/Register";
import MyRIB from "./pages/MyRIB";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PaymentSuccessfull from "./pages/PaymentSuccessfull";
import FindACar from "./pages/FindACar";
import NotFoundPage from "./pages/NotFoundPage";
import Contact from "./pages/Contact";
import ScrollToTop from "./pages/ScrollToTop";
import Policy from "./pages/Policy";
import Mentions from "./pages/Mentions";










function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const unsusbcribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoaded(true);
    });
    return () => unsusbcribe();
  };

  return (
    <>
     <ScrollToTop />
      <PayPalScriptProvider
        options={{ "client-id": process.env.REACT_APP_PAYPAL_TEST_CLIENT }}
      >
        <Routes>
          {/* Routes accessibles à tous */}
          <Route path="/" element={<Home />} />
        
          <Route path="/mentions-legales" element={<Mentions />} />
          <Route path="/comment-ca-marche" element={<HowItsWork />} />
          <Route
            path="/politique-de-confidentialite"
            element={<Policy />}
          />
          <Route path="/termes-et-conditions" element={<HowItsWork />} />
          <Route path="/contact" element={<Contact />} />

          {/* Routes accessibles uniquement si l'utilisateur n'est PAS authentifié */}
          {!authenticated ? (
            <>
              <Route path="/connexion" element={<Login />} />
              <Route path="/inscription" element={<Register />} />
              <Route path="/mot-de-passe-oublié" element={<ForgotPassword />} />
            </>
          ) : (
            /* Si authentifié, redirige vers le dashboard */
            <>
              <Route
                path="/connexion"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/inscription"
                element={<Navigate to="/dashboard" replace />}
              />
              <Route
                path="/mot-de-passe-oublié"
                element={<Navigate to="/dashboard" replace />}
              />
            </>
          )}

          {/* Routes accessibles uniquement si l'utilisateur est authentifié */}
          {authenticated ? (
            <>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/publier-une-recherche" element={<PublishACar />} />
              <Route path="/j-ai-trouver-un-vehicule" element={<FindACar />} />
              <Route path="/véhicule-retrouvé" element={<HowItsWork />} />
              <Route path="/mes-recherches" element={<MySearchs />} />
              <Route path="/mes-trouvailles" element={<MyFinds />} />
              <Route path="/mon-profil" element={<PersonalInformations />} />
              <Route path="/mon-rib" element={<MyRIB />} />
              <Route path="/paiement-validé" element={<PaymentSuccessfull />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route
                path="/laisser-un-commentaire"
                element={<WriteComment />}
              />
              <Route path="/ajouter-un-vehicule" element={<Searchs />} />
            </>
          ) : (
            /* Si non authentifié, redirige vers la page de connexion */
            <>
              <Route
                path="/dashboard"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/publier-une-recherche"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/j-ai-trouver-un-vehicule"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/véhicule-retrouvé"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/mes-recherches"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/mes-trouvailles"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/mon-profil"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/mon-rib"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/paiement-validé"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/notifications"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/laisser-un-commentaire"
                element={<Navigate to="/connexion" replace />}
              />
              <Route
                path="/ajouter-un-vehicule"
                element={<Navigate to="/connexion" replace />}
              />
            </>
          )}

          {/* Route pour la page 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <ToastContainer />
      </PayPalScriptProvider>
    </>
  );
}

export default App;
